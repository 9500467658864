<template>
  <div class="helpwrap">
    <div class="help-each" v-for="(item,index) in helpList" :key="index">
      <el-card :body-style="{ padding: '0px' }">
        <img :src="item.src" class="image">
        <div class="help-box"> 
          <div class="title">{{item.title}}</div>
          <div class="context">{{item.context}}</div>
          <div class="time">更新于：{{item.time}}</div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "help",
  data() {
    return {
      helpList:[  //帮助数据
        {
          src:'https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png',
          title:'好吃的汉堡',
          context:'一句话描述一句话描述',
          time:dayjs(new Date()).format('YYYY-MM-DD'),
        },
        {
          src:'https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png',
          title:'好吃的汉堡',
          context:'一句话描述一句话描述',
          time:dayjs(new Date()).format('YYYY-MM-DD'),
        },
        {
          src:'https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png',
          title:'好吃的汉堡',
          context:'一句话描述一句话描述',
          time:dayjs(new Date()).format('YYYY-MM-DD'),
        },
        {
          src:'https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png',
          title:'好吃的汉堡',
          context:'一句话描述一句话描述',
          time:dayjs(new Date()).format('YYYY-MM-DD'),
        },
        {
          src:'https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png',
          title:'好吃的汉堡',
          context:'一句话描述一句话描述',
          time:dayjs(new Date()).format('YYYY-MM-DD'),
        },
        {
          src:'https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png',
          title:'好吃的汉堡',
          context:'一句话描述一句话描述',
          time:dayjs(new Date()).format('YYYY-MM-DD'),
        },
        {
          src:'https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png',
          title:'好吃的汉堡',
          context:'一句话描述一句话描述',
          time:dayjs(new Date()).format('YYYY-MM-DD'),
        },
      ]
    };
  },
};
</script>

<style lang="scss" scoped>
  .helpwrap{
    padding: 0px 63px 0 55px;
    font-family: PingFang SC;
    display: flex;
    justify-content: flex-start;
    flex-wrap:wrap;
    .help-each{
      display: inline-block;
      box-sizing:border-box;
      padding: 34px 15px 0 15px;
      width: 20%;
      .help-box{
        margin:24px;
        text-align: left;
        .title{
          font-size: 18px;
          font-weight: bold;
          line-height: 25px;
          color: #E8E8E8;
        }
        .context{
          line-height: 23px;
          color: #B9B9B9;
          font-size: 16px;
          margin-top: 10px;
        }
        .time{
          font-size: 14px;
          line-height: 21px;
          color: #8A8A8A;
          margin-top: 10px;
        }
      }
      .image {
        width: 100%;
        height: 166px;
        display: block;
      }
    }
  }
  ::v-deep .el-card{
    background: #232323;
  }
</style>
